import { Layer, Source, useMap } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useCallback, useEffect, useState } from "react";
import { addActiveLayer, isActiveLayer, removeActiveLayer, selectIsLayerActive, useActivateMapLayer, useLoadMapImage, selectBeforeIDs, useMapEvent, zoomInOnCluster, useMapHoverEvent } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "axios";
import mandi_m from './images/MANDI_M.png';
import valve_pin from './images/VALVE_PIN.png';
import valve_pin_conflict from './images/VALVE_PIN_Conflict.png';
import valve_pin_conflict_cluster from './images/VALVE_PIN_Conflict_Cluster.png';
import valve_pin_conflict_selected from './images/VALVE_PIN_Conflict_Selected.png';
import valve_hovered_cluster from './images/Valve_Hovered_Cluster.png';
import valve_cluster_count from './images/VALVE_Cluster_Count.png';
import valve_default_cluster_count from './images/Valve_Default_Cluster_Count.png';
import valve_selected_cluster from './images/Valve_Selected_Cluster.png';
import cp_pin from './images/CP_PIN.png';
import cp_pin_conflict from './images/CP_PIN_Conflict.png';
import cp_pin_conflict_cluster from './images/CP_PIN_Conflict_Cluster.png';
import cp_pin_conflict_selected from './images/CP_PIN_Conflict_Selected.png';
import cp_hovered_cluster from './images/CP_Hovered_Cluster.png';
import cp_cluster_count from './images/CP_Cluster_Count.png';
import cp_default_cluster_count from './images/CP_Default_Cluster_Count.png';
import cp_selected_cluster from './images/CP_Selected_Cluster.png';
import cp10_pin from './images/CP10_PIN.png';
import cp10_pin_conflict from './images/CP10_PIN_Conflict.png';
import cp10_pin_conflict_cluster from './images/CP10_PIN_Conflict_Cluster.png';
import cp10_pin_conflict_selected from './images/CP10_PIN_Conflict_Selected.png';
import cp10_hovered_cluster from './images/CP10_Hovered_Cluster.png';
import cp10_cluster_count from './images/CP10_Cluster_Count.png';
import cp10_default_cluster_count from './images/CP10_Default_Cluster_Count.png';
import cp10_selected_cluster from './images/CP10_Selected_Cluster.png';
import leaks_pin from './images/LEAKS_PIN.png';
import leaks_pin_conflict from './images/LEAKS_PIN_Conflict.png';
import leaks_pin_conflict_cluster from './images/LEAKS_PIN_Conflict_Cluster.png';
import leaks_pin_conflict_selected from './images/LEAKS_PIN_Conflict_Selected.png';
import leaks_hovered_cluster from './images/LEAKS_Hovered_Cluster.png';
import leaks_cluster_count from './images/Leaks_Cluster_Count.png';
import leaks_default_cluster_count from './images/Leaks_Default_Cluster_Count.png';
import leaks_selected_cluster from './images/Leaks_Selected_Cluster.png';

import r_survey_icon from './images/RoutineSurvey/R_Survey_Default.png';
import r_survey_cluster_count from './images/RoutineSurvey/R_Survey_Conflict_Cluster_Count.png';
import r_survey_conflict_cluster_icon from './images/RoutineSurvey/R_Survey_Conflict_Cluster.png';
import r_survey_hovered_cluster from './images/RoutineSurvey/R_Survey_Conflict_Hovered_Cluster.png';
import r_survey_conflict_selected_icon from './images/RoutineSurvey/R_Survey_Conflict_Selected.png';
import r_survey_conflict_icon from './images/RoutineSurvey/R_Survey_Conflict.png';
import r_survey_default_cluster_count from './images/RoutineSurvey/R_Survey_Default_Cluster_Count.png';
import r_survey_selected_cluster from './images/RoutineSurvey/R_Survey_Selected_Cluster.png';

import s_survey_icon from './images/SpecialSurvey/S_Survey_Default.png';
import s_survey_conflict_icon from './images/SpecialSurvey/S_Survey_Conflict.png';
import s_survey_conflict_cluster_icon from './images/SpecialSurvey/S_Survey_Conflict_Cluster.png';
import s_survey_conflict_selected_icon from './images/SpecialSurvey/S_Survey_Conflict_Selected.png';
import s_survey_hovered_cluster from './images/SpecialSurvey/S_Survey_Conflict_Hovered_Cluster.png';
import s_survey_cluster_count from './images/SpecialSurvey/S_Survey_Conflict_Cluster_Count.png';
import s_survey_default_cluster_count from './images/SpecialSurvey/S_Survey_Default_Cluster_Count.png';
import s_survey_selected_cluster from './images/SpecialSurvey/S_Survey_Selected_Cluster.png';

import mandi_m_conflict from './images/MANDI_M_Conflict.png';
import mandi_m_conflict_cluster from './images/MANDI_M_Conflict_Cluster.png';
import mandi_m_highlighted_blue from './images/MANDI_M_Highlighted_Blue.png';
import mandi_m_highlighted_orange from './images/MANDI_M_Highlighted_Orange.png';
import { MandIWorkPopup } from "./popup";
import moment from 'moment';
import { selectSearchItems } from "redux/searchItems/selectors";
import { selectHighlightedMap, selectIsMandIDisabled, selectMandiCheckboxState, selectOverlapMandIIds, selectWorkTypes } from "redux/streetImprovements/selector";
import { setMAndIOptions, setOpenOrdersIdsToFilter } from "redux/streetImprovements/action";
import _ from 'lodash';

export const MANDI_WORK_LAYER = 'MANDI_WORK_LAYER';
export const MANDI_SPECIAL_SURVEY = 'MANDI_SPECIAL_SURVEY';
export const MANDI_WORK_SOURCE = 'MANDI_WORK_SOURCE';
export const MANDI_SPECIAL_SURVEY_SOURCE = 'MANDI_SPECIAL_SURVEY_SOURCE';
export const LSA_ID = 'LSAData';
export const SPECIAL_SURVEY_ID = 'SpecialSurveyData';
export const LEAKS_ID = 'Leaks';
export const CP10_ID = 'CP10Data';
export const VALVES_ID = 'ValvesData';
export const CPAREA_ID = 'CPAreaData';

export const MandIWorkLayerMetadata = {
    layerId: MANDI_WORK_LAYER,
    name: 'M&I Work',
    color: '#002762',
    layerSymbols: [
        { symbol: mandi_m, symbolName: 'mandi-m-icon' },
        { symbol: valve_pin, symbolName: 'valve-pin-icon' },
        { symbol: cp_pin, symbolName: 'cp-pin-icon' },
        { symbol: cp10_pin, symbolName: 'cp10-pin-icon' },
        { symbol: leaks_pin, symbolName: 'leaks-pin-icon' },
        { symbol: mandi_m_conflict, symbolName: 'mandi-m-conflict-icon' },
        { symbol: mandi_m_conflict_cluster, symbolName: 'mandi-m-conflict-cluster-icon' },
        { symbol: mandi_m_highlighted_blue, symbolName: 'mandi-m-highlighted-blue-icon' },
        { symbol: mandi_m_highlighted_orange, symbolName: 'mandi-m-highlighted-orange-icon' }
    ]
}

export const SpecialSurveyLayerMetadata = {
    layerId: MANDI_SPECIAL_SURVEY,
    name: 'Special Survey',
    color: '#002762'
}

export const footToMeter = (num) => {
    return num * 0.3048
}

//this property will be used to identify special surveys MatCodeOfLeakSurveyOrder 306-308,339 need special pin
//LeakObjectID unique ID used to identify overlaps

const LEAK_RADIUS = 250

const generateComparisons = (array, value) => {
    return array.map(element => ['in', ['to-string', element], value])
}

const fetchMandIData = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/get_mandi_data`;

    const filter = {
        return_type: 'geojson'
    }

    // start_time and end_time
    if (filterOptions?.timeFrame?.startDate && filterOptions?.timeFrame?.endDate) {
        filter.end_timeframe = moment(filterOptions?.timeFrame.endDate).format('YYYY-MM-DD')
        filter.start_timeframe = moment(filterOptions?.timeFrame.startDate).format('YYYY-MM-DD')
    }

    const mandiWorkTypes = filterOptions?.workTypes?.filter((element) => {
        return element.includes("[M&I]")
    })
    // work type

    // if (mandiWorkTypes && mandiWorkTypes.length > 0) {
    //     filter.id = mandiWorkTypes.map(element => parseInt(element.split(" ")[0]));
    // }

    if (filterOptions?.municipalities && filterOptions?.municipalities.length > 0) {
        filter.municipality = filterOptions.municipalities
    }

    // district
    if (filterOptions?.districts && filterOptions.districts.length > 0) {
        filter.district = filterOptions.districts;
    }

    filter.routine_surveys = false
    filter.special_surveys = false
    filter.excavations = true

    axios.post(url, filter)
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false)
            })  
        })
        .catch((err) => {
            callBack(null, true)
        })
}

const fetchData = (endpoint, callBack, filterOptions = {}) => {
    const url = `${APIGateways.streetimprovements}${endpoint}`;

    axios.post(url, filterOptions)
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false);
            });
        })
        .catch((err) => {
            callBack(null, true);
        });
}

const fetchLSAData = (callBack, filterOptions) => {
    fetchData('/open_order/lsa', callBack, filterOptions);
}

const fetchCP10Data = (callBack, filterOptions) => {
    fetchData('/open_order/cp10', callBack, filterOptions);
}

const fetchCPAreaData = (callBack, filterOptions) => {
    fetchData('/open_order/cp_area', callBack, filterOptions);
}

const fetchValvesData = (callBack, filterOptions) => {
    fetchData('/open_order/valves', callBack, filterOptions);
}

const fetchLeaksData = (callBack, filterOptions) => {
    fetchData('/open_order/leaks', callBack, filterOptions);
}

function filterData(data, selectedOrderNumbers) {

    if (selectedOrderNumbers.length === 0 || !data) {
        return data
    }

    const filteredData = {
        type: 'FeatureCollection',
        features: data.features.filter(feature => {
            if ('order_number' in data.features[0].properties) {
                return selectedOrderNumbers.includes(feature.properties.order_number)
            } else {
                return selectedOrderNumbers.includes(Number(feature.properties.OpenOrderNumber))

            }
        })
    }

    return filteredData
}

export const MandIWorkLayer = ({showLayer=true, showConflicts=true}) => {

    const dispatch = useDispatch()
    const beforeIDs = useSelector(selectBeforeIDs)

    const [showMandIWorkLayer] = useActivateMapLayer(MandIWorkLayerMetadata, showLayer);
    const [showMandISpecialSurvey] = useActivateMapLayer(SpecialSurveyLayerMetadata, showLayer);
    const showOverlaps = useSelector((state) => selectIsLayerActive(state, 'MANDI_CITY_WORK_OVERLAP'))
    const showOverlapRadius = useSelector((state) => selectIsLayerActive(state, 'MANDI_CITY_WORK_OVERLAP_RADIUS'))
    const overlapMandIIDs = useSelector(selectOverlapMandIIds) ?? []
    const searchItems = useSelector(selectSearchItems);
    const workTypes = useSelector(selectWorkTypes)
    const surveyLeakCodes = ['306', '307', '308', '339']
    const [specialSurveyData, setSpecialSurveyData] = useState(null);
    const [mAndIData, setMandIData] = useState(null);
    const [lsaData, setLsaData] = useState(null);
    const [cp10Data, setCp10Data] = useState(null);
    const [valvesData, setValvesData] = useState(null);
    const [cpAreaData, setCpAreaData] = useState(null);
    const [leaksData, setLeaksData] = useState(null)

    const selectedCityWork = useSelector(selectHighlightedMap)
    const mandiCheckboxStates = useSelector(selectMandiCheckboxState)

    const isMandIDisabled = useSelector(selectIsMandIDisabled)
    const [selectedOrderNumbers, setSelectedOrderNumbers] = useState([])
    const [geojsonDataSources, setGeojsonDataSources] = useState([])

    const { current: map } = useMap()

    useEffect(() => {
        if (!workTypes) {
            return
        }

        const orderNumbers = []
        let currentOrderNum

        for (let i = 0; i < workTypes.length; i++) {
            if (workTypes[i].includes("[M&I]")) {
                currentOrderNum = workTypes[i]

                currentOrderNum = currentOrderNum.substring(0, currentOrderNum.indexOf("[M&I]"))

                orderNumbers.push(Number(currentOrderNum))
            }
        }

        setSelectedOrderNumbers(orderNumbers)

    }, [workTypes])

    useEffect(() => {
        if (showConflicts) {
            dispatch(addActiveLayer('MANDI_CITY_WORK_OVERLAP'))
        } else {
            dispatch(removeActiveLayer('MANDI_CITY_WORK_OVERLAP'))
        }
    }, [showConflicts])

    useEffect(() => {
        // Combine all datasets and their respective checkbox states
        const datasets = [
            { data: leaksData, isChecked: mandiCheckboxStates[2], property: 'order_number' },
            { data: cp10Data, isChecked: mandiCheckboxStates[0], property: 'order_number' },
            { data: cpAreaData, isChecked: mandiCheckboxStates[1], property: 'order_number' },
            { data: lsaData, isChecked: mandiCheckboxStates[3], property: 'order_number' },
            { data: specialSurveyData, isChecked: mandiCheckboxStates[5], property: 'order_number' },
            { data: valvesData, isChecked: mandiCheckboxStates[4], property: 'order_number' }
        ];

        let newMatchingIDs = [];

        // Ensure overlapMandIIDs is defined
        datasets.forEach(({ data, isChecked, property }) => {
            if (!isChecked && data) {
                const matchedIDs = data.features
                    .map(feature => Number(feature.properties[property]));
    
                newMatchingIDs = [...new Set([...newMatchingIDs, ...matchedIDs])];
            }
        });
        dispatch(setOpenOrdersIdsToFilter(newMatchingIDs));
    }, [overlapMandIIDs, leaksData, cp10Data, cpAreaData, lsaData, specialSurveyData, valvesData, mandiCheckboxStates]);

    // Get every order number once all data is loaded and save to state
    // to use in Work Type filter
    useEffect(() => {
        const datasets = [ leaksData, cp10Data, cpAreaData, lsaData, specialSurveyData, valvesData ]

        if (!datasets.every(dataset => dataset !== null)) return;

        // const getOrderNumber = (dataset, attribute) => {
        //     return dataset.features.map(feature => feature.properties[attribute])
        // }

        let orderNumbers = []

        for (const dataset of datasets) {
            if (dataset !== null) {
                for (const feature of dataset.features) {
                    if ('order_number' in feature.properties) {
                        orderNumbers.push(feature.properties.order_number + ' [M&I]')
                    } else {
                        orderNumbers.push(feature.properties.OpenOrderNumber + ' [M&I]')
                    }
                }
            }
        }
        
        dispatch(setMAndIOptions(orderNumbers))

    }, [ leaksData, cp10Data, cpAreaData, lsaData, specialSurveyData, valvesData])

    // useEffect(() => {
    //     // dispatch(addToMapLoadingList('MandI'))
    //     fetchMandIData((data, isError) => {
    //         if (!isError) {
    //             // setData(data)
    //             let mandI = _.cloneDeep(data);
    //             mandI.features = mandI.features.filter(element => !surveyLeakCodes.includes(element.properties.MatcodeDetectionOrder))
    //             setMandIData(mandI)

    //             let specialSurvey = _.cloneDeep(data);
    //             specialSurvey.features = specialSurvey.features.filter(element => surveyLeakCodes.includes(element.properties.MatcodeDetectionOrder))
    //             //setSpecialSurveyData(specialSurvey)

    //             // if ( searchItems.workTypes.length === 0 ) {
    //                 let arr = []
    //                 data.features.forEach( element => {
    //                     element.properties.Order &&  arr.push({ orderNumber: element.properties.Order + " [M&I]", personID: "-" })
    //                 })
    //                 dispatch(setMAndIOptions(arr))
    //             // }
    //         } 
    //         // dispatch(removeFromMapLoadingList('MandI'))
    //     }, searchItems)
    // }, [searchItems]) // Add searchItems to dependencies to enable searching

    useEffect(() => {

        const filter = {}

        if (searchItems?.timeFrame?.startDate && searchItems?.timeFrame?.endDate) {
            filter.end_timeframe = moment(searchItems?.timeFrame.endDate).format('YYYY-MM-DD')
            filter.start_timeframe = moment(searchItems?.timeFrame.startDate).format('YYYY-MM-DD')
        }

        // Fetch LSA Data
        fetchLSAData((data, isError) => {
            if (!isError) {
                let cloned = _.cloneDeep(data);
                let routineSurveys = cloned.features.filter((item) => !surveyLeakCodes.includes(item.properties.matcode))
                setLsaData({...cloned, features: routineSurveys})
                let specialSurveys = cloned.features.filter((item) => surveyLeakCodes.includes(item.properties.matcode))
                setSpecialSurveyData({...cloned, features: specialSurveys})
            } 
        }, filter)

        // Fetch CP10 Data
        fetchCP10Data((data, isError) => {
            if (!isError) {
              setCp10Data(data);
            }
          }, filter);
  
        // Fetch Valves Data
        fetchValvesData((data, isError) => {
            if (!isError) {
              setValvesData(data);
            }
          }, filter);
  
          // Fetch CPArea Data
        fetchCPAreaData((data, isError) => {
            if (!isError) {
              setCpAreaData(data);
            }
          }, filter);

        fetchLeaksData((data, isError) => {
        if (!isError) {
            setLeaksData(data)
        }
        }, filter);

    }, [searchItems]) // Add searchItems to dependencies to enable searching

    useLoadMapImage('mandi-m-icon', mandi_m)
    useLoadMapImage('valve-pin-icon', valve_pin)
    useLoadMapImage('valve-pin-conflict-icon', valve_pin_conflict)
    useLoadMapImage('valve-pin-conflict-cluster-icon', valve_pin_conflict_cluster)
    useLoadMapImage('valve-pin-conflict-selected-icon', valve_pin_conflict_selected)
    useLoadMapImage('valve-pin-cluster-count-icon', valve_cluster_count)
    useLoadMapImage('valve-pin-default-cluster-count-icon', valve_default_cluster_count)
    useLoadMapImage('valve-pin-hovered-cluster-icon', valve_hovered_cluster)
    useLoadMapImage('valve-pin-selected-cluster-icon', valve_selected_cluster)
    useLoadMapImage('cp-pin-icon', cp_pin)
    useLoadMapImage('cp-pin-conflict-icon', cp_pin_conflict)
    useLoadMapImage('cp-pin-conflict-cluster-icon', cp_pin_conflict_cluster)
    useLoadMapImage('cp-pin-conflict-selected-icon', cp_pin_conflict_selected)
    useLoadMapImage('cp-pin-cluster-count-icon', cp_cluster_count)
    useLoadMapImage('cp-pin-default-cluster-count-icon', cp_default_cluster_count)
    useLoadMapImage('cp-pin-hovered-cluster-icon', cp_hovered_cluster)
    useLoadMapImage('cp-pin-selected-cluster-icon', cp_selected_cluster)
    useLoadMapImage('cp10-pin-icon', cp10_pin)
    useLoadMapImage('cp10-pin-conflict-icon', cp10_pin_conflict)
    useLoadMapImage('cp10-pin-conflict-cluster-icon', cp10_pin_conflict_cluster)
    useLoadMapImage('cp10-pin-conflict-selected-icon', cp10_pin_conflict_selected)
    useLoadMapImage('cp10-pin-cluster-count-icon', cp10_cluster_count)
    useLoadMapImage('cp10-pin-default-cluster-count-icon', cp10_default_cluster_count)
    useLoadMapImage('cp10-pin-hovered-cluster-icon', cp10_hovered_cluster)
    useLoadMapImage('cp10-pin-selected-cluster-icon', cp10_selected_cluster)
    useLoadMapImage('leaks-pin-icon', leaks_pin)
    useLoadMapImage('leaks-pin-conflict-icon', leaks_pin_conflict)
    useLoadMapImage('leaks-pin-conflict-cluster-icon', leaks_pin_conflict_cluster)
    useLoadMapImage('leaks-pin-conflict-selected-icon', leaks_pin_conflict_selected)
    useLoadMapImage('leaks-pin-cluster-count-icon', leaks_cluster_count)
    useLoadMapImage('leaks-pin-default-cluster-count-icon', leaks_default_cluster_count)
    useLoadMapImage('leaks-pin-hovered-cluster-icon', leaks_hovered_cluster)
    useLoadMapImage('leaks-pin-selected-cluster-icon', leaks_selected_cluster)

    useLoadMapImage('r-survey-icon', r_survey_icon)
    useLoadMapImage('r-survey-pin-cluster-count-iconn', r_survey_cluster_count)
    useLoadMapImage('r-survey-conflict-cluster-icon', r_survey_conflict_cluster_icon)
    useLoadMapImage('r-survey-pin-hovered-cluster-icon', r_survey_hovered_cluster)
    useLoadMapImage('r-survey-conflict-selected-icon', r_survey_conflict_selected_icon)
    useLoadMapImage('r-survey-conflict-icon', r_survey_conflict_icon)
    useLoadMapImage('r-survey-pin-default-cluster-count-icon', r_survey_default_cluster_count)
    useLoadMapImage('r-survey-pin-selected-cluster-icon', r_survey_selected_cluster)

    useLoadMapImage('s-survey-icon', s_survey_icon)
    useLoadMapImage('s-survey-conflict-icon', s_survey_conflict_icon)
    useLoadMapImage('s-survey-conflict-cluster-icon', s_survey_conflict_cluster_icon)
    useLoadMapImage('s-survey-conflict-selected-icon', s_survey_conflict_selected_icon)
    useLoadMapImage('s-survey-pin-cluster-count-icon', s_survey_cluster_count)
    useLoadMapImage('s-survey-pin-default-cluster-count-icon', s_survey_default_cluster_count)
    useLoadMapImage('s-survey-pin-hovered-cluster-icon', s_survey_hovered_cluster)
    useLoadMapImage('s-survey-pin-selected-cluster-icon', s_survey_selected_cluster)

    useLoadMapImage('mandi-m-conflict-icon', mandi_m_conflict)
    useLoadMapImage('mandi-m-conflict-cluster-icon', mandi_m_conflict_cluster)
    useLoadMapImage('mandi-m-highlighted-blue-icon', mandi_m_highlighted_blue)
    useLoadMapImage('mandi-m-highlighted-orange-icon', mandi_m_highlighted_orange)

    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);
    const [openOrderType, setOpenOrderType] = useState(null);
    const [hoveredLayer, setHoveredLayer] = useState('')
    
    useMapEvent('click', MANDI_WORK_LAYER, (e) => {
        if (!isActiveLayer(e, MANDI_WORK_LAYER)) return;

        const feature = e.features[0]

        if (feature.properties.point_count > 10) {

            zoomInOnCluster(map, MANDI_WORK_SOURCE, e.features)
    
            return
        }

        setShowPopup(true);
        setPopupFeature(feature);
        setLatLng(e.lngLat);
        setTarget(e.target);
        setOpenOrderType(LEAKS_ID);
    })

    useMapEvent('click', `${LSA_ID}-layer`, (e) => {
        if (!isActiveLayer(e, `${LSA_ID}-layer`)) return;

        const feature = e.features[0]

        if (feature.properties.point_count > 10) {

            zoomInOnCluster(map, LSA_ID, e.features)
    
            return
        }

        setShowPopup(true);
        setPopupFeature(feature);
        setLatLng(e.lngLat);
        setTarget(e.target);
        setOpenOrderType(LSA_ID);
    })

    useMapEvent('click', `${SPECIAL_SURVEY_ID}-layer`, (e) => {
        if (!isActiveLayer(e, `${SPECIAL_SURVEY_ID}-layer`)) return;

        const feature = e.features[0]

        if (feature.properties.point_count > 10) {

            zoomInOnCluster(map, SPECIAL_SURVEY_ID, e.features)
    
            return
        }

        setShowPopup(true);
        setPopupFeature(feature);
        setLatLng(e.lngLat);
        setTarget(e.target);
        setOpenOrderType(SPECIAL_SURVEY_ID);
    })

    useMapEvent('click', `${VALVES_ID}-layer`, (e) => {
        if (!isActiveLayer(e, `${VALVES_ID}-layer`)) return;

        const feature = e.features[0]

        if (feature.properties.point_count > 10) {

            zoomInOnCluster(map, VALVES_ID, e.features)
    
            return
        }
        setShowPopup(true);
        setPopupFeature(feature);
        setLatLng(e.lngLat);
        setTarget(e.target);
        setOpenOrderType(VALVES_ID);
    })

    useMapEvent('click', `${CP10_ID}-layer`, (e) => {
        if (!isActiveLayer(e, `${CP10_ID}-layer`)) return;

        const feature = e.features[0]

        if (feature.properties.point_count > 10) {

            zoomInOnCluster(map, CP10_ID, e.features)
    
            return
        }

        setShowPopup(true);
        setPopupFeature(feature);
        setLatLng(e.lngLat);
        setTarget(e.target);
        setOpenOrderType(CP10_ID);
    })

    useMapEvent('click', `${CPAREA_ID}-layer`, (e) => {
        if (!isActiveLayer(e, `${CPAREA_ID}-layer`)) return;

        const feature = e.features[0]

        if (feature.properties.point_count > 10) {

            zoomInOnCluster(map, CPAREA_ID, e.features)
    
            return
        }

        setShowPopup(true);
        setPopupFeature(feature);
        setLatLng(e.lngLat);
        setTarget(e.target);
        setOpenOrderType(CPAREA_ID);
    })

    useMapEvent('click', `${MANDI_WORK_SOURCE}-layer`, (e) => {
        if (!isActiveLayer(e, `${MANDI_WORK_SOURCE}-layer`)) return;

        const feature = e.features[0]

        if (feature.properties.point_count > 10) {

            zoomInOnCluster(map, MANDI_WORK_SOURCE, e.features)
    
            return
        }

        setShowPopup(true);
        setPopupFeature(feature);
        setLatLng(e.lngLat);
        setTarget(e.target);
        setOpenOrderType('Leaks');
    })

    // useMapHoverEvent(MANDI_WORK_LAYER, (e) => {
    //     setHoveredLayer(e.features[0].properties.cluster_id)
    // }, 300)
    
    // useMapHoverEvent(`${LSA_ID}-layer`, (e) => {
    //     setHoveredLayer(e.features[0].properties.cluster_id)
    // }, 300)

    // useMapHoverEvent(`${VALVES_ID}-layer`, (e) => {
    //     setHoveredLayer(e.features[0].properties.cluster_id)
    // }, 300)

    // useMapHoverEvent(`${CP10_ID}-layer`, (e) => {
    //     setHoveredLayer(e.features[0].properties.cluster_id)
    // }, 300)

    // useMapHoverEvent(`${CPAREA_ID}-layer`, (e) => {
    //     setHoveredLayer(e.features[0].properties.cluster_id)
    // }, 300)

      // Data sources
   useEffect(() => {

    setGeojsonDataSources([
        { 
            id: LSA_ID, 
            data: filterData(lsaData, selectedOrderNumbers), 
            radius: footToMeter(125), 
            icon: 'r-survey-icon', 
            conflict_icon: 'r-survey-conflict-icon', 
            cluster_icon: 'r-survey-conflict-cluster-icon', 
            selectedIcon: 'r-survey-conflict-selected-icon',
            cluster_count_icon: 'r-survey-pin-cluster-count-icon',
            default_cluster_count_icon: 'r-survey-pin-default-cluster-count-icon',
            cluster_hovered_icon: 'r-survey-pin-hovered-cluster-icon',
            selected_cluster: 'r-survey-pin-selected-cluster-icon',
        },
        { 
            id: SPECIAL_SURVEY_ID, 
            data: filterData(specialSurveyData, selectedOrderNumbers), 
            radius: footToMeter(125), 
            icon: 's-survey-icon', 
            conflict_icon: 's-survey-conflict-icon', 
            cluster_icon: 's-survey-conflict-cluster-icon', 
            selectedIcon: 's-survey-conflict-selected-icon',
            cluster_count_icon: 's-survey-pin-cluster-count-icon',
            default_cluster_count_icon: 's-survey-pin-default-cluster-count-icon',
            cluster_hovered_icon: 's-survey-pin-hovered-cluster-icon',
            selected_cluster: 's-survey-pin-selected-cluster-icon',
        },
        { 
            id: CP10_ID, 
            data: filterData(cp10Data, selectedOrderNumbers), 
            radius: footToMeter(125), 
            icon: 'cp10-pin-icon', 
            conflict_icon: 'cp10-pin-conflict-icon', 
            cluster_icon: 'cp10-pin-conflict-cluster-icon', 
            selectedIcon: 'cp10-pin-conflict-selected-icon',
            cluster_count_icon: 'cp10-pin-cluster-count-icon',
            default_cluster_count_icon: 'cp10-pin-default-cluster-count-icon',
            cluster_hovered_icon: 'cp10-pin-hovered-cluster-icon',
            selected_cluster: 'cp10-pin-selected-cluster-icon',
        },
        { 
            id: VALVES_ID, 
            data: filterData(valvesData, selectedOrderNumbers), 
            radius: footToMeter(125), 
            icon: 'valve-pin-icon', 
            conflict_icon: 'valve-pin-conflict-icon', 
            cluster_icon: 'valve-pin-conflict-cluster-icon', 
            selectedIcon: 'valve-pin-conflict-selected-icon',
            cluster_count_icon: 'valve-pin-cluster-count-icon',
            default_cluster_count_icon: 'valve-pin-default-cluster-count-icon',
            cluster_hovered_icon: 'valve-pin-hovered-cluster-icon',
            selected_cluster: 'valve-pin-selected-cluster-icon',
        },
        { 
            id: CPAREA_ID, 
            data: filterData(cpAreaData, selectedOrderNumbers), 
            radius: footToMeter(125), 
            icon: 'cp-pin-icon', 
            conflict_icon: 'cp-pin-conflict-icon', 
            cluster_icon: 'cp-pin-conflict-cluster-icon', 
            selectedIcon: 'cp-pin-conflict-selected-icon',
            cluster_count_icon: 'cp-pin-cluster-count-icon',
            cluster_hovered_icon: 'cp-pin-hovered-cluster-icon',
            default_cluster_count_icon: 'cp-pin-default-cluster-count-icon',
            selected_cluster: 'cp-pin-selected-cluster-icon',
        },
        { 
            id: MANDI_WORK_SOURCE, 
            data: filterData(leaksData, selectedOrderNumbers), 
            radius: footToMeter(125), 
            icon: 'leaks-pin-icon', 
            conflict_icon: 'leaks-pin-conflict-icon', 
            cluster_icon: 'leaks-pin-conflict-cluster-icon', 
            selectedIcon: 'leaks-pin-conflict-selected-icon',
            cluster_count_icon: 'leaks-pin-cluster-count-icon',
            default_cluster_count_icon: 'leaks-pin-default-cluster-count-icon',
            cluster_hovered_icon: 'leaks-pin-hovered-cluster-icon',
            selected_cluster: 'leaks-pin-selected-cluster-icon',
        },
    ]);
   }, [lsaData, specialSurveyData, cp10Data, valvesData, cpAreaData, leaksData, selectedOrderNumbers])

    const checkboxStates = {
        CP10Data: mandiCheckboxStates[0],
        CPAreaData: mandiCheckboxStates[1],
        MANDI_WORK_LAYER: mandiCheckboxStates[2],
        LSAData: mandiCheckboxStates[3],
        SpecialSurveyData: mandiCheckboxStates[5],
        ValvesData: mandiCheckboxStates[4],
        MANDI_WORK_SOURCE: mandiCheckboxStates[2],
    }

    const createSourceConfig = (id, data) => ({
        id: id,
        type: "geojson",
        data: data,
        cluster: true,
        clusterMaxZoom: 18,
        clusterRadius: 60,
        clusterProperties: {
            OpenOrderNumberList: ['concat', ['concat', ['to-number', ['get', 'order_number']], ', '], ['accumulated']],
        },
      });

//cp-pin-cluster-count-icon
    const createLayerConfig = (
        id, 
        sourceId, 
        visibility, 
        icon, 
        conflict_icon, 
        cluster_icon, 
        selectedIcon, 
        cluster_count_icon, 
        cluster_hovered_icon, 
        default_cluster_count_icon, 
        selectedCluster
    ) => {
        
        return {
            id: id,
            type: 'symbol',
            source: sourceId,
            layout: {
            visibility: visibility && checkboxStates[sourceId] && !isMandIDisabled ? 'visible' : 'none',
            'icon-image': [
                'step',
                ['zoom'],
                [
                    'case',
                    ['!', ['literal', showOverlaps]], icon,
                    ['==', ['to-number', ['get', 'order_number']], selectedCityWork ? selectedCityWork.sap_id : 100], selectedIcon,
                    ['in', ['to-number', ['get', 'order_number']], ['literal', overlapMandIIDs]], conflict_icon,
                    [
                        'all',
                        ['has', 'OpenOrderNumberList'],
                        [
                            'any', 
                            ...generateComparisons(overlapMandIIDs, ['get', 'OpenOrderNumberList'])
                        ]
                    ], cluster_icon,
                    icon
                ],
                22, [
                    'case',
                    ['!', ['literal', showOverlaps]], icon,
                    ['==', ['to-number', ['get', 'order_number']], selectedCityWork ? selectedCityWork.sap_id : 100], selectedIcon,
                    ['in', ['to-number', ['get', 'order_number']], ['literal', overlapMandIIDs]], conflict_icon,
                    [
                        'all',
                        ['has', 'OpenOrderNumberList'],
                        [
                            'any',
                            ...generateComparisons(overlapMandIIDs, ['get', 'OpenOrderNumberList'])
                        ],
                        ['==', ['get', 'cluster_id'], hoveredLayer === undefined ? 1 : hoveredLayer]
                    ], cluster_hovered_icon, // Check if the cluster_id matches hoveredLayer
                    [
                        'all',
                        ['has', 'OpenOrderNumberList'],
                        [
                            'any',
                            ...generateComparisons([selectedCityWork ? selectedCityWork.sap_id : 100], ['get', 'OpenOrderNumberList'])
                        ],
                    ], selectedCluster, // Check if the cluster_id matches hoveredLayer
                    [
                        'all',
                        ['has', 'OpenOrderNumberList'],
                        [
                            'any', 
                            ...generateComparisons(overlapMandIIDs, ['get', 'OpenOrderNumberList'])
                        ]
                    ], cluster_count_icon,
                    [
                        'has', 'OpenOrderNumberList'
                    ], 
                    default_cluster_count_icon,
                    icon
                ]
            ],
            'text-field': [
                'step',
                ['zoom'],
                '', // Default to no text
                22, [
                    'case',
                    [
                        'all',
                        ['has', 'point_count'],
                        ['>', ['get', 'point_count'], 1],
                    ],
                    ['get', 'point_count'],
                    ''
                ]
            ],
            'text-size': 12,
            'text-allow-overlap': true,
            'text-offset': [
                'case',
                [
                    'all',
                    ['has', 'OpenOrderNumberList'],
                    [
                        'any',
                        ...generateComparisons([selectedCityWork ? selectedCityWork.sap_id?.toString() : '100'], ['get', 'OpenOrderNumberList'])
                    ]
                ],
                [0.3, 0], // Offset when condition is true
                [0, 0]  // Default offset
            ], // Move the text 1 unit right and 1.5 units up
            'icon-size': 1,
            'icon-allow-overlap': true,
            },
            paint: {
            'icon-translate': [0, -27],
            'text-color': '#D45D00',
            'text-translate': [22, -53],
            },
            beforeId: selectedCityWork ? null : beforeIDs.symbol,
        };
      };


    const createCircleLayerConfig = (id, sourceId, radius) => {
        // if (sourceId === 'LSAData' || sourceId === 'CPAreaData') {
        //   return null;
        // }
        return {
          id: id,
          source: sourceId,
          type: "circle",
          layout: {
            visibility: showOverlapRadius && showMandIWorkLayer && checkboxStates[sourceId] && !isMandIDisabled ? 'visible' : 'none'
          },
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#333333',
            'circle-stroke-color': '#bababa',
            'circle-stroke-width': 2,
            'circle-opacity': 0,
            'circle-radius': [
              'interpolate',
              ['exponential', 2],
              ['zoom'],
              0, 0,
              20, [
                '/',
                ['/', radius, 0.075],
                //using LA's Lat for now
                ['cos', ['*', 34.0549, ['/', Math.PI, 180]]],
              ],
            ],
          },
          beforeId: beforeIDs.circle
        };
      };

    return (
        <>
         <MandIWorkPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
                openOrderType={openOrderType}
            />
        {geojsonDataSources.map((source) => {
            const sourceConfig = createSourceConfig(source.id, source.data);
            const layerConfig = createLayerConfig(
                `${source.id}-layer`, 
                source.id, showMandIWorkLayer, 
                source.icon, source.conflict_icon, 
                source.cluster_icon, 
                source.selectedIcon, 
                source.cluster_count_icon,
                source.cluster_hovered_icon,
                source.default_cluster_count_icon,
                source.selected_cluster,
            );
            const circleConfig = createCircleLayerConfig(`${source.id}-layer-circle`, source.id, source.radius);

            return (
            <Source key={source.id} {...sourceConfig}>
                {(!!layerConfig) ? <Layer {...layerConfig} /> : ''}
                {(!!circleConfig) ? <Layer {...circleConfig} /> : ''}
            </Source>
            );
        })}
        </>

    )
}

export const MandIWorkLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, MandIWorkLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <div >
            <p style={{marginBottom: '8px'}}><img src={mandi_m} alt="M&I Work Logo" height="29px"/> M&I Work</p>
        </div>
    )
}

export const MandIWorkOverlapLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, MandIWorkLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <div >
            <p style={{marginBottom: '8px'}}><img src={mandi_m_conflict_cluster} alt="M&I Work Logo" height="29px"/> M&I Work (&gt;= 1 Overlaps)</p>
            <p style={{marginBottom: '8px'}}><img src={mandi_m_conflict} alt="M&I Work Logo" height="29px"/> M&I Work Overlap</p>
        </div>
    )
}