import {
    addToMapLoadingList,
    removeFromMapLoadingList,
} from '@innovation-toolkit/mapbox';
import _ from 'lodash'
import { Button, openNotification, Typography, Menu, LayerTabWrapper, TitleDivider } from '@innovation-toolkit/ui';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileUploadedIcon from 'assets/icons/checkbox_success.svg?react';
import FileUploadErrorIcon from 'assets/icons/error_sign.svg?react';
import {
    setCityWorkAggregationMapData,
    setShowCityWorkAggregationTable,
    selectCityWorkAggregationMapData,
} from '@redux/cityWorkAggregation/slice';

import { APIGateways } from '@config/config';
import axios from 'axios';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { CityWorkAggregationEdit } from './edit';
import FileUpload from '../selfService/FileUpload';
import {Buffer} from 'buffer';
import { GATEWAY_WEBSOCKET_URL } from 'config/config';
import { useUserToken } from '@innovation-toolkit/rbac';

export const uploadFileToS3 = (file) => new Promise(async (resolve, reject) => {
    // Get presigned url to upload file to S3
    const res = await axios.post(
        `${APIGateways.citywork}/aggregation-upload`,
        {},
        { headers: { 'file-name': file.name } }
    );
    const presignedUrl = res.data.presigned.url;
    const fields = res.data.presigned.fields

    // Create a FormData object to hold the file that is 
    // being uploaded
    const formData = new FormData();
    Object.keys(fields)
        .forEach((key) => {
        formData.append(key, fields[key]);
    });    
    formData.append('file', file);

    // Upload file to S3
    try {
        await fetch(presignedUrl, { method: 'POST', body: formData })
    } catch {
        reject("Failed to upload file")
    }

    // Return information about where the file was uploaded to
    resolve(res.data.S3_Path)
})

export const FileUploadStatus = ({ successful, saved }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'space-around',
                gap: '16px',
                height: '48px',
                width: '100%',
                marginBottom: '16px',
            }}
        >
            {saved ? (
                <>
                    <FileUploadedIcon />
                    <h1 style={{ marginBottom: '0px' }}>Data Submitted</h1>
                </>
            ) : successful ? (
                <>
                    <FileUploadedIcon />
                    <h1 style={{ marginBottom: '0px' }}>File Uploaded</h1>
                </>
            ) : (
                <>
                    <FileUploadErrorIcon />
                    <h1 style={{ marginBottom: '0px' }}>Errors Detected</h1>
                </>
            )}
        </div>
    );
};

export const formatWebsocketUrl = (userToken) => {
    return `${GATEWAY_WEBSOCKET_URL}?authorization=${userToken}`
}

export const CityWorkAggregationMenu = () => {
    const dispatch = useDispatch();

    const [isFileValid, setIsFileValid] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(null);

    const [refreshibleWebsocketUrl, setRefreshibleWebsocketUrl] = useState('')

    const [fileToGeocode, setFileToGeocode] = useState(null);
    const [cityMapDataFeatures, setCityMapDataFeatures] = useState([])
    const [numberResponsesExpected, setNumberResponsesExpected] = useState(0);
    const mapData = useSelector(selectCityWorkAggregationMapData)
    const [dataIsSubmitting, setDataIsSubmitting] = useState(false);

    const [saveData, setSaveData] = useState(false);
    const [wasDataSavedSuccessfully, setWasDataSavedSuccessfully] = useState(false);

    const [isWebsocketConnectionActive, setIsWebsocketConnectionActive] = useState(false);

    const { sendMessage, lastMessage } = useWebSocket(refreshibleWebsocketUrl, {}, isWebsocketConnectionActive);

    const [isWebsocketSaveConnectionActive, setIsWebsocketSaveConnectionActive] = useState(false);
    const { sendMessage: sendSaveMessage, lastMessage: lastSaveMessage } = useWebSocket(refreshibleWebsocketUrl, {}, isWebsocketSaveConnectionActive);

    const [userToken] = useUserToken();

    useEffect(() => {

        if (!userToken) {
            return
        }

        async function uploadFile(fileToGeocode, userToken) {
            if (!fileToGeocode) return;

            setRefreshibleWebsocketUrl(formatWebsocketUrl(userToken))
            setIsWebsocketConnectionActive(true)
            setWasDataSavedSuccessfully(false)
            dispatch(addToMapLoadingList('bulk-upload-layer'))
    
            sendMessage(JSON.stringify({
                action: 'scg-async',
                resource: '/webform/excel_processing',
                uploaded_data_uri: fileToGeocode,
                save_data: saveData,
                accessToken: userToken
            }));
    
            setFileToGeocode(null);
            setSaveData(false)
        }

        uploadFile(fileToGeocode, userToken)

    }, [fileToGeocode, userToken])

    // After each message received from the websocket append te new data to the
    // data errors array and map features array
    useEffect(() => {
        if (!lastMessage) return;

        const response = JSON.parse(lastMessage.data)

        if (response.error) {
            // handle the error
            openNotification({
                type: 'error', 
                header: 'File Submission Error 3',
                notificationMessage: 'An error occurred while trying to upload the submitted city work. Please refresh and try again.'
            })


            return;
        } else if (response.complete) {
            // Upload was successful, display on map and table

            fetch(response.web_form_table)
                .then((res) => res.json())
                .then((geocodedData) => {

                    setCityMapDataFeatures(geocodedData);
                    dispatch(removeFromMapLoadingList('bulk-upload-layer'))
                    dispatch(setShowCityWorkAggregationTable(true))
                    dispatch(setCityWorkAggregationMapData(geocodedData))
                })
    

        } else {
            // Other communications, potentially the loading indicator in the future
        }

        return;

    }, [lastMessage])

    useEffect(() => {
        if (!lastSaveMessage) return
        const response = JSON.parse(lastSaveMessage.data)
        setDataIsSubmitting(false)

        if (response.statusCode === 200) {
            setWasDataSavedSuccessfully(true);
            openNotification({
                type: 'success', 
                header: 'File Submitted',
                notificationMessage: 'City work data submitted. This data will become available within the Street Improvements tool once it has finished processing. If there is more data to submit please upload a new file.'
            })
        }
        else if (response.message = "Endpoint request timed out") {
            // Handle timeout
        } else {
            openNotification({
                type: 'error', 
                header: 'File Submission Error',
                notificationMessage: 'An error occurred while trying to save the submitted city work. Please upload and try again.'
            })
        }

    }, [lastSaveMessage])

    const onSaveButtonPressed = async (userToken) => {
        setDataIsSubmitting(true)

        const fileName = `city-work-aggregtion-upload.geojson`

        // Update Websocket url with valid value
        setRefreshibleWebsocketUrl(formatWebsocketUrl(userToken))

        // Get presigned upload url
        let response 

        try {
            response = await axios.post(`${APIGateways.citywork}/aggregation-upload`, {}, {
                headers: {
                    'file-name': fileName
                }
            })
        }
        catch (error) {
            openNotification({
                type: 'error', 
                header: 'File Submission Error 1',
                notificationMessage: 'An error occurred while trying to save the submitted city work. Please upload and try again.'
            })
            return
        }

        const { S3_Path, presigned } = response.data;

        // Generate form to upload file
        const buf = Buffer.from(JSON.stringify(mapData));
        const formData = new FormData();
        Object.keys(presigned.fields).forEach(key => {
            formData.append(key, presigned.fields[key]);
        });
        formData.append('file', buf);

        // Upload geojson to S3
        try {
            await fetch(presigned.url, { method: 'POST', body: formData })
        }
        catch (error) {
            openNotification({
                type: 'error', 
                header: 'File Submission Error 2',
                notificationMessage: 'An error occurred while trying to save the submitted city work. Please upload and try again.'
            })
            return
        }
        
        // Send to lambda to save data
        setIsWebsocketSaveConnectionActive(true)
        sendSaveMessage(JSON.stringify({
            action: 'scg-async',
            resource: '/webform/save_webform_submission',
            uploaded_data_uri: S3_Path,
            accessToken: userToken
        }));

    };

    return (
        <Menu
            centered={true}
            tabs={[
                {
                    title: 'Upload',
                    content: (
                        <LayerTabWrapper>
                            <section style={{marginTop: '16px'}}>
                                <Typography text='File Download' variant="Heading 2"/>
                                <TitleDivider />
                                <Typography 
                                    variant='Body 1'
                                    text='To submit multiple projects, 
                                    please download the excel file 
                                    below, fill in each of the project 
                                    details on a separate line, and 
                                    save the file to your computer.'/>
                                <Button 
                                    title='DOWNLOAD XLSX' 
                                    variant='secondary' 
                                    size='L'
                                    onClick={async () => {
                                        const response = await axios.get(`${APIGateways.citywork}/get-form-template`)
                                        let newWindow = window.open(response.data);
                                        newWindow.opener = null;
                                    }}
                                    style={{margin: '16px auto 30px auto'}}
                                />

                                <Typography text='File Upload' variant="Heading 2"/>
                                <TitleDivider />
                                <Typography 
                                    variant='Body 1'
                                    text='Please upload the saved file in the upload
                                    area below. Once uploaded, the file will be
                                    ready to be submitted.'
                                    style={{paddingBottom: '16px'}}/>
                                    
                                {isFileUploaded && isFileValid ? ( // When the file is uploaded and valid then the FileUpload component is hidden
                                    <></>
                                ) : (
                                    <FileUpload
                                    dispatch={dispatch}
                                    skipPopup={true}
                                    text = 'Click or drag a single file to this area to upload'
                                    acceptedFiles=".xlsx"
                                    beforeUpload={()=>true}
                                    customRequest={(options) => {
                                        dispatch(setCityWorkAggregationMapData(null))
                                        setCityMapDataFeatures([])

                                        uploadFileToS3(options.file)
                                            .then((s3Path) => {
                                                setFileToGeocode(s3Path)

                                                options.onSuccess(s3Path, options.file)
                                            })
                                    }}
                                    getFileStatus={(status) => {return}
                                            // console.log('fileStatus', status)
                                        }
                                />
                                )}
                                {isFileValid && (
                                    <FileUploadStatus
                                        successful={isFileValid}
                                    />
                                )}
                                
                                <Button
                                    disabled={dataIsSubmitting || mapData?.features?.some(element => element.properties.has_error) || cityMapDataFeatures.length === 0 || numberResponsesExpected !== 0 || wasDataSavedSuccessfully}
                                    title="Submit File"
                                    style={{
                                        margin: '16px auto 24px auto',
                                    }}
                                    onClick={() => onSaveButtonPressed(userToken)}
                                    tooltipText={dataIsSubmitting ? "Data is being submitted" : "To save, data must be uploaded with no errors"}
                                />
                                { wasDataSavedSuccessfully && (
                                    <FileUploadStatus
                                        saved={wasDataSavedSuccessfully}
                                    />
                                )}
                                
                            </section>
                        </LayerTabWrapper>
                    ),
                },
                {
                    title: 'Edit',
                    content: <CityWorkAggregationEdit/>
                },
            ]}
        />
    );
};