import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PinIcon } from 'shared/style/iconography/PinIcon';
import ExportIcon from "assets/icons/export.svg?react";

import { setConcentratedDataSource } from 'shared/redux/ConcentratedData';
import { setSelfServiceSimpleAnalyticsAttribute, setShowSimpleAnalyticsTable } from '@redux/selfService/action';
import { selectFileUploadInfo, selectShowSimpleAnalytics, selectSimpleAnalyticsTableSetUp, selectUploadColumns, selectNumericUploadColumns, selectSimpleAnalyticsTableData, selectUploadKey } from '@redux/selfService/selector';

import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { getCountOnCensusTract } from '@innovation-toolkit/mapbox';
import { AnalysisSection, AnalysisSectionContainer, Button, SmallDropDown } from '@innovation-toolkit/ui';

const AttributeSelector = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 20px;
    margin-bottom: 8px;

    h4 {
        margin-bottom: 0px;
    }
`

export const InvestmentAnalysis = () => {
    const dispatch = useDispatch();

    const internalChargingSource = {}//useSelector(selectInternalChargingSource);
    const externalChargingSource = {}//useSelector(selectExternalChargingSource);
    const selectedSimpleAnalyticsTableSetUp = useSelector(selectSimpleAnalyticsTableSetUp);
    const showSimpleAnalytics = useSelector(selectShowSimpleAnalytics)
    const fileColumns = useSelector(selectUploadColumns);
    const numericColumns = useSelector(selectNumericUploadColumns)
    const dataToExport = useSelector(selectSimpleAnalyticsTableData)
    const fileName = useSelector(selectUploadKey)

    const dataSetOptions = ['SDG&E Stations', 'External Stations'];
    const placeholderAggregation = ['Average', 'Min', 'Max', 'Sum', 'None'];

    const [exportDisabled, setExportDisabled] = useState(true)

    const handleDropDownSelect = async (value) => {
        switch(value) {
            case 'SDG&E Stations':
                updateConcentratedData(internalChargingSource);
                break;
            case 'External Stations':
                updateConcentratedData(externalChargingSource);
                break;
            default: 
                break;
        }
    }

    useEffect(() => {
        handleDropDownSelect(dataSetOptions[0]);
    }, [])

    const updateConcentratedData = (source, isGeojson = true) => {
        if (source === null || source === undefined || source?.features?.length <= 0 || Object.keys(source).length === 0) return; 

        const tractCount = getCountOnCensusTract(source, "tract", isGeojson);
        dispatch(setConcentratedDataSource(tractCount));
    }

    const handleDataExport = () => {
        const headers = ["", ...dataToExport.colHeaders]
        let values = []
        
        dataToExport.row.forEach((row, index) => {
            values[index] = [row.rowHeader, ...row.data]
        })

        const ExcelData = [headers, ...values]
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const name = fileName.split(".")[0]

        const ws = XLSX.utils.aoa_to_sheet(ExcelData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const XLSXdata = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(XLSXdata, name + fileExtension);
    }

    useEffect(() => {
        if (dataToExport !== null && Object.keys(dataToExport).length > 0) {
            setExportDisabled(false)
        } else {
            setExportDisabled(true)
        }
    }, [dataToExport])

    return (
        <AnalysisSectionContainer style={{marginTop: '8px'}}>
                {/* <div>
                    <div className="body_1">{ strings.app.investment.analysis.concentratedData.description }</div>
                    <h3 style={{paddingTop: '16px'}}>{ strings.app.investment.analysis.concentratedData.dropdown }</h3>
                    <DropDown
                        options={dataSetOptions}
                        onChange={handleDropDownSelect}
                    />
                </div> */}

                <AnalysisSection
                    title='Simple Analytics'
                    action={setShowSimpleAnalyticsTable}
                    defaultState={showSimpleAnalytics}
                    Icon={PinIcon}
                    description='Select the group attributes you wish to see in the simple analytics table (top of screen).'
                >
                    {<>
                        <AttributeSelector>
                            <h4>Rows</h4>
                            <SmallDropDown 
                                options={['DAC75']}//{fileColumns}
                                selectedOption={selectedSimpleAnalyticsTableSetUp.rows}
                                onSelectChange={(option) => dispatch(setSelfServiceSimpleAnalyticsAttribute({ rows: option}))}
                                type='small'
                            />
                        </AttributeSelector>
                        <AttributeSelector>
                            <h4>Columns</h4>
                            <SmallDropDown 
                                options={fileColumns}
                                selectedOption={selectedSimpleAnalyticsTableSetUp.columns}
                                onSelectChange={(option) => dispatch(setSelfServiceSimpleAnalyticsAttribute({ columns: option}))}
                                type='small'
                            />
                        </AttributeSelector>
                        <AttributeSelector>
                            <h4>Value</h4>
                            <SmallDropDown 
                                options={numericColumns}
                                selectedOption={selectedSimpleAnalyticsTableSetUp.values}
                                onSelectChange={(option) => dispatch(setSelfServiceSimpleAnalyticsAttribute({ values: option}))}
                                type='small'
                            />
                        </AttributeSelector>
                        <AttributeSelector>
                            <h4>Value Type</h4>
                            <SmallDropDown 
                                options={["Number", "Dollar"]}
                                selectedOption={"Number"}
                                onSelectChange={(option) => dispatch(setSelfServiceSimpleAnalyticsAttribute({ valueType: option}))}
                                type='small'
                            />
                        </AttributeSelector>
                        <AttributeSelector>
                            <h4>Aggregation</h4>
                            <SmallDropDown 
                                options={placeholderAggregation}
                                selectedOption={selectedSimpleAnalyticsTableSetUp.aggregation}
                                onSelectChange={(option) => dispatch(setSelfServiceSimpleAnalyticsAttribute({ aggregation: option}))}
                                type='small'
                            />
                        </AttributeSelector>
                        <Button 
                            type='secondary'
                            onClick={handleDataExport}
                            title='XLSX EXPORT'
                            svgIcon={<ExportIcon />}
                            size='L'
                            style={{marginTop: '16px'}}
                            disabled={exportDisabled}
                        />
                        
                    </>}
                </AnalysisSection>
        </AnalysisSectionContainer>
    )
}