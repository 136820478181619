import axios from 'axios'

export const createCommunitiesOption = features => features &&
    features
        .reduce((acc, obj) => {
            // const dac = obj.properties.dac === 'DAC'
            // if (dac) {
                const tract = obj.tract
                const city = obj.city
                if (!acc[city]) {
                    return {...acc, ...{[city]: [tract]}}
                }
                return {...acc, [city]: [...acc[city], tract]}
            // }
            // return {...acc}

        }, {})

export const fetchZippedJsonData = (url, callBack, filterOptions = {},  method = 'get') => {
    axios
        [method](url, filterOptions)
        .then((response) => {
            return axios
                .get(response.data, {
                    transformRequest: (data, headers) => {
                        delete headers['Authorization'];
                        return data;
                    },
                    decompress: true,
                    responseType: 'arraybuffer',
                    responseType: 'blob'
                })
                .then(async (fetchResponse) => {
                    const ds = new DecompressionStream("gzip");
                    const decompressedStream = fetchResponse.data.stream().pipeThrough(ds);
                    const decompressed_blob = await new Response(decompressedStream).blob();
                    const decompressedTextData = await decompressed_blob.text()
                    const decompressedJSONData = JSON.parse(decompressedTextData)
            
                    return callBack(decompressedJSONData)
                });
        })
        .catch((err) => {
            console.log(err);
            callBack(null, true);
        });
};