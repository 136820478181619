import { useDispatch, useSelector } from "react-redux"
import { MandIWorkLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { DelimiterLine, InfoIcon, LayerToggle, LayerToggleIcon, LayerToggleIconChild, Typography } from '@innovation-toolkit/ui';
import ProjectIcon from 'assets/icons/mandi_icon.svg?react';
import ValvesIcon from 'assets/icons/valves-menu-logo.svg?react';
import CPAreaIcon from 'assets/icons/cp-area-menu-logo.svg?react';
import CP10Icon from 'assets/icons/cp10-menu-logo.svg?react';
import LeaksIcon from 'assets/icons/leaks-menu-logo.svg?react';
import SurveyIcon from 'assets/icons/survey.svg?react';
import { SIcon } from "assets/icons/toggle_s_icon";
import { RIcon } from "assets/icons/toggle_r_icon";
// import { PIcon } from "assets/icons/PIcon";
import { MIcon } from "assets/icons/MIcon";
import { selectFilteringLayer } from "redux/streetImprovements/selector";
import { useEffect, useState } from "react";
import { Checkbox } from "antd";
import styled from "styled-components";
import { setMandiCheckboxStates } from "redux/streetImprovements/action";
import { SpecialSurveyLayerMetadata } from "../SurveyLayer/SpecialSurveyLayer";
import { RoutineSurveyLayerMetadata } from "../SurveyLayer/RoutineSurveyLayer";
import { APIGateways } from "config/config";
import axios from 'axios'

const StyledCheckbox = styled(Checkbox)`
//   margin: auto 32px auto auto;
  .ant-checkbox {
    margin-top: 0;
  }

  .ant-checkbox .ant-checkbox-inner {
      background-color: #ffffff00;
      border: 2px solid #ffffff !important;
    }
`

const fetchMandiRefreshDate = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/layer_update_date`;

    const filter = {
        layer: 'mandi_open_orders'
    }

    axios.post(url, filter)
        .then((response) => {
            callBack(response.data, false)
        })
        .catch((err) => {
            callBack(null, true)
        })// NotificationType for work type
}

export const MandIWorkToggle = () => {
    const MANDI_WORK_LAYER = MandIWorkLayerMetadata.layerId
    const OVERLAP_LAYER = 'MANDI_CITY_WORK_OVERLAP';
    const OVERLAP_RADIUS_LAYER = 'MANDI_CITY_WORK_OVERLAP_RADIUS'

    const dispatch = useDispatch()
    const shouldShowMANDIWork = useSelector((state) => selectIsLayerActive(state, MANDI_WORK_LAYER))
    const workTypeFilterActiveLayer = useSelector(selectFilteringLayer)
    const layerShowState = [
        useSelector((state) => selectIsLayerActive(state, OVERLAP_LAYER)),
        useSelector((state) => selectIsLayerActive(state, OVERLAP_RADIUS_LAYER)),
    ];
    const [checkboxStates, setCheckboxStates] = useState([true, true, true, true, true, true, true, true]);

    const handleCheckboxChange = (index) => {
        const newStates = [...checkboxStates];
        newStates[index] = !newStates[index];
        setCheckboxStates(newStates);
    }

    useEffect(() => {
        dispatch(setMandiCheckboxStates(checkboxStates))
    }, [checkboxStates])

    const [lastRefreshDate, setLastRefreshDate] = useState(null)

    useEffect(() => {

        fetchMandiRefreshDate((data, isError) => {
            if (!isError) {
                setLastRefreshDate(data)
            } 
        })
        
    }, [])


    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(MANDI_WORK_LAYER))
        } else {
            dispatch(addActiveLayer(MANDI_WORK_LAYER))
        }
    }

    const handleIndividualChange = (layerID, index) => {
        if (layerShowState[index]) {
            dispatch(removeActiveLayer(layerID))
        } else {
            dispatch(addActiveLayer(layerID))
        }
    }

    useEffect(() => {
        if (workTypeFilterActiveLayer === 'CPD') {
            dispatch(removeActiveLayer(MANDI_WORK_LAYER))
        } else {
            dispatch(addActiveLayer(MANDI_WORK_LAYER))
        }

    }, [workTypeFilterActiveLayer])

    const TooltipMessage = <div>
        This layer is “Zoom Dependent”. Zoom in to view individual projects and overlaps.<br/>
        <span><b style={{fontWeight: 'bold'}}>Last Refresh Date:</b> {lastRefreshDate?.last_updated}</span>
    </div>

    return (
        <LayerToggle
            title='M&I Work'
            // componentIcon={ProjectIcon}
            onChange={handleClick}
            headerStyle={{marginTop: '10px'}}
            bodyStyle={{backgroundColor: '#0E263B'}}
            tooltipText={lastRefreshDate ? TooltipMessage : undefined}
            disabled={workTypeFilterActiveLayer === 'CPD' }
            currentState={shouldShowMANDIWork}
            children={
                <section style={{display: 'flex', flexDirection: 'column'}}>
                <div
                    style={{
                        position: 'relative' /* Position relative for the parent div */,
                        padding: '5px 0px 11px 10px',
                }}
                >
                <div
                    style={{
                        position: 'absolute',
                        left: -8 /* Position on the left */,
                        top: 13 /* Align with the top */,
                        height: '90%' /* Match the height of the parent */,
                        width: '1.5px' /* Width of the border */,
                        backgroundColor:
                            '#FFFFFF33' /* Color of the border */,
                    }}
                />
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={ValvesIcon} 
                                IconOffset={[0, -1]} 
                                fillColor="#D45D00"
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title="Valve Open Orders"
                        subLayerCheckbox={{defaultChecked: checkboxStates[4], onChange: () => handleCheckboxChange(4)}}
                    />
                    <div style={{margin:'15px'}}></div>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={CPAreaIcon} 
                                IconOffset={[0, -1]} 
                                fillColor="#D45D00"
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title="CP Area Open Orders"
                        subLayerCheckbox={{defaultChecked: checkboxStates[1], onChange: () => handleCheckboxChange(1)}}
                    />
                    <div style={{margin:'15px'}}></div>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={CP10Icon} 
                                IconOffset={[0, -1]} 
                                fillColor="#D45D00"
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title="CP10 Open Orders"
                        subLayerCheckbox={{defaultChecked: checkboxStates[0], onChange: () => handleCheckboxChange(0)}}
                    />
                    <div style={{margin:'15px'}}></div>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={RIcon} 
                                IconOffset={[4, 4]} 
                                fillColor="#0E263B"
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title="Routine Surveys"
                        subLayerCheckbox={{defaultChecked: checkboxStates[3], onChange: () => handleCheckboxChange(3)}}
                    />
                    <div style={{margin:'15px'}}></div>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={SIcon} 
                                IconOffset={[4, 4]} 
                                fillColor="#0E263B"
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title="Special Surveys"
                        subLayerCheckbox={{defaultChecked: checkboxStates[5], onChange: () => handleCheckboxChange(5)}}
                    />
                    <div style={{margin:'15px'}}></div>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={LeaksIcon} 
                                IconOffset={[0, -1]} 
                                fillColor="#D45D00"
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title="Leaks"
                        subLayerCheckbox={{defaultChecked: checkboxStates[2], onChange: () => handleCheckboxChange(2)}}
                    />
                    <div >
                    </div>
                    {/* <DelimiterLine style={{marginLeft: '-29px'}}/>  */}
                    <div style={{margin: '10px'}}></div>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={() => <MIcon fillColor="white"/>} 
                                IconOffset={[4, 4]} 
                                fillColor="#D45D00"
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title={<span style={{fontSize: '12px'}}>M&I Overlaps with City Work</span>}
                        subLayerCheckbox={{defaultChecked: layerShowState[0], onChange: () => handleIndividualChange(OVERLAP_LAYER, 0)}}
                    />
                    <div style={{margin: '10px'}}></div>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="circle"
                                fillColor="#BABABA"
                                strokeColor="black" 
                                outlineColor="white"
                            />
                        } 
                        title="Overlap Radius"
                        subLayerCheckbox={{defaultChecked: layerShowState[1], onChange: () => handleIndividualChange(OVERLAP_RADIUS_LAYER, 1)}}
                    />
                    </div>
                </section>
            }
        />
    )
}